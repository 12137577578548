import {
  // Remove the following line
  // useLoaderData,
  useNavigation,
  Link,
} from '@remix-run/react';
import { Avatar } from '@/components/ui/avatar';
import { json } from '@remix-run/node';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from '@/components/ui/card';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { Skeleton } from '@/components/ui/skeleton';

export async function loader() {
  const env = process.env.NODE_ENV;
  return json({ env });
}

export default function Signin() {
  const navigation = useNavigation();

  const apiUrl = import.meta.env.VITE_API_URL;
  const googleAuthUrl = `${apiUrl}/users/auth/google_oauth2`;

  const isLoading =
    navigation.state === 'submitting' || navigation.state === 'loading';

  useEffect(() => {
    try {
      posthog.reset();
    } catch (error) {
      console.error('Error identifying user:', error);
    }
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-background-tertiary">
      <Avatar
        src="/logo.png"
        alt="Logo"
        className="w-10 h-10 absolute top-6 left-6"
      />
      <Card className="w-[350px]">
        <CardHeader>
          <CardTitle>Sign In</CardTitle>
          <CardDescription>
            Enter your credentials to access your account
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form
            method="post"
            className="space-y-4"
            action={`${apiUrl}/users/sign_in`}
            encType="application/x-www-form-urlencoded"
            credentials="include"
          >
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              {isLoading ? (
                <Skeleton className="h-10 w-full" />
              ) : (
                <Input id="email" name="email" type="email" required />
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              {isLoading ? (
                <Skeleton className="h-10 w-full" />
              ) : (
                <Input id="password" name="password" type="password" required />
              )}
            </div>

            <div className="flex justify-between items-center">
              <Link
                to="/forgot-password"
                className="text-sm text-blue-600 hover:underline"
              >
                Forgot password?
              </Link>
            </div>
            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading ? 'Signing in...' : 'Sign in'}
            </Button>
          </form>
        </CardContent>
        <CardFooter>
          <a href={googleAuthUrl}>
            <Button variant="outline" className="w-full" disabled={isLoading}>
              Sign in with Google
            </Button>
          </a>
        </CardFooter>
      </Card>
    </div>
  );
}
